import { RouteLocationNormalized } from 'vue-router'

export const scrollTo = async (to: RouteLocationNormalized) => {
  // As scrollIntoView doesn't work properly when scrolling to sticky elements from the bottom, we need to scroll to the top of the page first
  scrollToTop()
  return tryScrollToElement(to) ?? (await tryScrollToHash(to))
}

const scrollToTop = () => {
  document.getElementsByTagName('main')[0]?.scrollTo(0, 0)
}

const tryScrollToElement = (to: RouteLocationNormalized) => {
  if (to.meta.scrollTo !== 'element') {
    return undefined
  }

  const scrollTarget =
    document.querySelector('[data-scroll-to-me="true"]') ?? // Get truthy data first
    document.querySelector('[data-scroll-to-me]') // Fallback to the shorthand

  if (!scrollTarget) {
    throw new Error(
      'Scroll target not found! Set `meta: {scrollTo: "top"}` for the current view'
    )
  }
  scrollTarget?.scrollIntoView({ block: 'start', inline: 'start' })

  return {}
}

const tryScrollToHash = async (to: RouteLocationNormalized): Promise<void> => {
  if (!to.hash) {
    return
  }

  const intervalTimeout = 100
  const maxIntervalCount = 30 // 30 * 100ms = 3s
  let intervalCount = 0

  await new Promise<void>((resolve, reject) => {
    const attemptScroll = () => {
      const scrollTarget = document.querySelector(to.hash)

      if (scrollTarget) {
        scrollTarget.scrollIntoView({ block: 'start', inline: 'start' })
        resolve()
      } else if (intervalCount++ > maxIntervalCount) {
        reject(new Error(`Scroll target not found! Check the hash: ${to.hash}`))
      } else {
        setTimeout(attemptScroll, intervalTimeout)
      }
    }

    attemptScroll()
  })
}
